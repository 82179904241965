/* ============================================================
 * To Pause / Play Videos inline - Single Video (desktop)
 * ============================================================ */

const curatedBySingleVideos = document.querySelectorAll(
	'.sg--module-video-single .single-video--desktop',
);

if (curatedBySingleVideos.length > 0) {
	curatedBySingleVideos.forEach((shoppingGuideVideo) => {
		const vidContainer = shoppingGuideVideo.querySelectorAll('.video_controls-container');

		vidContainer.forEach((button) => {
			button.addEventListener('click', function () {
				button.classList.toggle('clicked-me'); // video controls container

				const vid_controls = button.children[0];
				vid_controls.classList.toggle('playing');
				vid_controls.classList.toggle('stopped');

				if (vid_controls.classList.contains('playing')) {
					const control_pause = vid_controls.children[0];
					const control_play = vid_controls.children[1];

					// 1. Find video and play it
					shoppingGuideVideo.querySelector('.sg-module--video').play();
					// 3. Show Pause SVG
					control_pause.style.display = 'block';
					// 4. Hide Play SVG
					control_play.style.display = 'none';
				}

				if (vid_controls.classList.contains('stopped')) {
					const control_pause = vid_controls.children[0];
					const control_play = vid_controls.children[1];

					// 1. Find video and pause it
					shoppingGuideVideo.querySelector('.sg-module--video').pause();
					// 3. Show Play SVG
					control_play.style.display = 'block';
					// 4. Hide Pause SVG
					control_pause.style.display = 'none';
				}
			});
		});
	});
}

/* ============================================================
 * To Pause / Play Videos in Lightbox - Multi Video
 * ============================================================ */

const curatedByMultiVideos = document.querySelectorAll('.sg--module-video-multi');

// Loop through all video modules
if (curatedByMultiVideos.length > 0) {
	const lightbox = document.querySelector('.sg--module-video-multi .video-lightbox');
	const lightboxVideo = document.querySelector('.sg--module-video-multi #lightbox-video');
	const playPauseButton = document.querySelector('.sg--module-video-multi #play-pause-btn');
	const closeButton = document.querySelector('.sg--module-video-multi .lightbox-close');

	curatedByMultiVideos.forEach((singleVideoCard) => {
		const vidContainer = singleVideoCard.querySelectorAll('.video_controls-container');

		vidContainer.forEach((button) => {
			button.addEventListener('click', function () {
				// Get the video source from the clicked tile
				const videoSrc = button
					.closest('.video--container')
					.querySelector('video > source').src;

				// Set the lightbox video source and open lightbox
				lightboxVideo.src = videoSrc;
				lightbox.classList.remove('hidden');
				lightboxVideo.play();
				lightboxVideo.controls = false;
				lightboxVideo.loop = true;
				lightboxVideo.muted = true;
				playPauseButton.classList.remove('is-paused');
				playPauseButton.classList.add('is-playing');
				playPauseButton.textContent = 'Pause'; // Set button text to 'Pause' when video is playing
			});
		});
	});

	// Play/Pause Button inside the lightbox
	playPauseButton.addEventListener('click', function () {
		if (lightboxVideo.paused) {
			lightboxVideo.play();
			playPauseButton.textContent = 'Pause';
			playPauseButton.classList.add('is-playing');
			playPauseButton.classList.remove('is-paused');
		} else {
			lightboxVideo.pause();
			playPauseButton.textContent = 'Play';
			playPauseButton.classList.remove('is-playing');
			playPauseButton.classList.add('is-paused');
		}
	});

	// Add an event listener for the close button to hide the lightbox
	closeButton.addEventListener('click', function () {
		lightbox.classList.add('hidden');
		lightboxVideo.pause();
		lightboxVideo.src = ''; // Clear video source to stop playing
		playPauseButton.textContent = 'Play';
		playPauseButton.classList.remove('is-playing');
		playPauseButton.classList.add('is-paused');
	});

	// Add event listener for 'Escape' key press to close the lightbox
	document.addEventListener('keydown', function (e) {
		if (e.key === 'Escape') {
			lightbox.classList.add('hidden');
			lightboxVideo.pause();
			lightboxVideo.src = ''; // Clear video source to stop playing
			playPauseButton.textContent = 'Play';
			playPauseButton.classList.remove('is-playing');
			playPauseButton.classList.add('is-paused');
		}
	});
}

/* ============================================================
 * To Pause / Play Videos in Lightbox - Single Video (mobile)
 * ============================================================ */

const curatedBySingleVideoMobile = document.querySelectorAll(
	'.sg--module-video-single .single-video--mobile',
);

// Loop through all video modules
if (curatedBySingleVideoMobile.length > 0) {
	const lightbox = document.querySelector('.sg--module-video-single .video-lightbox');
	const lightboxVideo = document.querySelector('.sg--module-video-single #lightbox-video');
	const playPauseButton = document.querySelector('.sg--module-video-single #play-pause-btn');
	const closeButton = document.querySelector('.sg--module-video-single .lightbox-close');

	curatedBySingleVideoMobile.forEach((singleVideoCard) => {
		const vidContainer = singleVideoCard.querySelectorAll('.video_controls-container');

		vidContainer.forEach((button) => {
			button.addEventListener('click', function () {
				// Get the video source from the clicked tile
				const videoSrc = button
					.closest('.video--container')
					.querySelector('video > source').src;

				// Set the lightbox video source and open lightbox
				lightboxVideo.src = videoSrc;
				lightbox.classList.remove('hidden');
				lightboxVideo.play();
				lightboxVideo.controls = false;
				lightboxVideo.loop = true;
				lightboxVideo.muted = true;
				playPauseButton.classList.remove('is-paused');
				playPauseButton.classList.add('is-playing');
				playPauseButton.textContent = 'Pause'; // Set button text to 'Pause' when video is playing
			});
		});
	});

	// Play/Pause Button inside the lightbox
	playPauseButton.addEventListener('click', function () {
		if (lightboxVideo.paused) {
			lightboxVideo.play();
			playPauseButton.textContent = 'Pause';
			playPauseButton.classList.add('is-playing');
			playPauseButton.classList.remove('is-paused');
		} else {
			lightboxVideo.pause();
			playPauseButton.textContent = 'Play';
			playPauseButton.classList.remove('is-playing');
			playPauseButton.classList.add('is-paused');
		}
	});

	// Add an event listener for the close button to hide the lightbox
	closeButton.addEventListener('click', function () {
		lightbox.classList.add('hidden');
		lightboxVideo.pause();
		lightboxVideo.src = ''; // Clear video source to stop playing
		playPauseButton.textContent = 'Play';
		playPauseButton.classList.remove('is-playing');
		playPauseButton.classList.add('is-paused');
	});

	// Add event listener for 'Escape' key press to close the lightbox
	document.addEventListener('keydown', function (e) {
		if (e.key === 'Escape') {
			lightbox.classList.add('hidden');
			lightboxVideo.pause();
			lightboxVideo.src = ''; // Clear video source to stop playing
			playPauseButton.textContent = 'Play';
			playPauseButton.classList.remove('is-playing');
			playPauseButton.classList.add('is-paused');
		}
	});
}

const menuFocus = document.querySelectorAll(
	'.menu.menu--main .menu__navigation .menu-item-has-children',
);

menuFocus.forEach((item) => {
	item.setAttribute('tabindex', '0');
	item.addEventListener('keydown', function (e) {
		if (e.key === 'Enter') {
			this.classList.toggle('focus');
		}
	});
});

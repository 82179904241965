/* global sd */
/* ============================================================
 * Imports
 * ============================================================ */

import openTabs from './components/openTabs';
import removeElement from './components/removeElement';
import toggleElement from './components/toggleElement';
import CTCarousel from './libraries/carousel';
import { is } from './lib/utils';
// import convertTime12to24 from './components/convertTime12to24';
// General Website Functionality
import './libraries/jquery.nice-select';
import './components/menuFocus';
import './components/socialMenuFocus';
import './components/promosFilterFocus';
import './components/guided-pages-scroll-items';
import './components/sliders';
import './components/shopping-guide-videos-auto';
import './components/shopping-guide-videos-manual';
import './components/shopping-guide-show-more';
import './components/account-login-popup';
import './components/account-popups';

/* Featured Events Carousel */
const eventsCarousel = new CTCarousel();
eventsCarousel.init({
	id: '.section--carousel.--featured-events',
	slidenav: false,
	animate: false,
	startAnimated: false,
	additionalClasses: '',
});

/* Shoppable Blog Carousel */
const blogCarousel = new CTCarousel();
blogCarousel.init({
	id: '.shoppable-blog--modules .section--carousel',
	slidenav: false,
	animate: false,
	startAnimated: false,
	additionalClasses: '',
});

/* Featured Events Carousel */
// const luxStoresCarousel = new CTCarousel();
// luxStoresCarousel.init({
// 	id: '.section--carousel.--lux-stores',
// 	slidenav: false,
// 	animate: false,
// 	startAnimated: false,
// 	additionalClasses: '',
// });

// const convertTime12to24 = (time12h) => {
// 	const [time] = time12h.split(' ');

// 	let [hours] = time.split(':');

// 	if (hours === '12') {
// 		hours = '00';
// 	}

// 	const last2 = time12h.slice(-2);

// 	if (last2 === 'PM') {
// 		hours = parseInt(hours, 10) + 12;
// 	}
// 	return hours;
// };

const tabSection = document.querySelector('.tab-section');
if (tabSection) {
	console.log({ tabSection });
	openTabs(tabSection);
}

/* ============================================================
 * Store Hours
 * ============================================================ */

const openSelector = document.querySelectorAll('.open__hours');

openSelector.forEach((selector) => {
	const hourStatus = selector.querySelector('.hours-status');
	const { open } = hourStatus.dataset;
	const close = hourStatus.dataset.closed;

	const getCurrentDate24h = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, '0');
		const minutes = now.getMinutes().toString().padStart(2, '0');
		const seconds = now.getSeconds().toString().padStart(2, '0');

		return `${hours}:${minutes}:${seconds}`;
	};
	const currentTime = getCurrentDate24h();

	// Open/Close time for store internals
	// const openStringStores = selector.querySelector('.open__time.stores');
	// const closeStringStores = selector.querySelector('.close__time.stores');

	// Open/Close time for header
	const openStringDesktop = selector.querySelector('.open__time.desktop');
	const openStringMobile = selector.querySelector('.open__time.mobile');
	const closeStringHeader = selector.querySelector('.close__time.header');

	// Get screen widths
	const width = window.innerWidth;
	const width2 = document.documentElement.clientWidth;

	/* ============================================================
	 * HEADER
	 * ============================================================ */

	// Set innitially to hide
	if (openStringDesktop) {
		openStringDesktop.style.display = 'none';
	}
	if (openStringMobile) {
		openStringMobile.style.display = 'none';
	}

	if (width < 480 || width2 < 480) {
		if (openStringMobile && closeStringHeader) {
			if (currentTime >= open && currentTime <= close) {
				openStringMobile.style.display = 'inline';
				closeStringHeader.style.display = 'none';
			} else {
				openStringMobile.style.display = 'none';
				closeStringHeader.style.display = 'inline';
			}
		}
	} else if (openStringDesktop && closeStringHeader) {
		if (currentTime >= open && currentTime <= close) {
			openStringDesktop.style.display = 'inline';
			closeStringHeader.style.display = 'none';
		} else {
			openStringDesktop.style.display = 'none';
			closeStringHeader.style.display = 'inline';
		}
	}

	/* ============================================================
	 * STORE INTERNALS
	 * ============================================================ */

	// Set innitially to hide
	// if (openStringStores) {
	// 	openStringStores.style.display = 'none';
	// }
	// if (closeStringStores) {
	// 	closeStringStores.style.display = 'none';
	// }

	// if (openStringStores || closeStringStores) {
	// 	// if (16 >= 6 && 16 <= 15) {
	// 	if (currentTime >= open && currentTime <= close) {
	// 		// open
	// 		openStringStores.style.display = 'inline';
	// 		closeStringStores.style.display = 'none';
	// 	} else {
	// 		// closed
	// 		openStringStores.style.display = 'none';
	// 		closeStringStores.style.display = 'inline';
	// 	}
	// }
});

/* ============================================================
 * Cookies Popup
 * ============================================================ */

const cookieAccept = document.querySelector('#cookie-accept');
// const cookieDecline = document.querySelector('#cookie-decline');
if (cookieAccept) {
	const cookieChoice = localStorage.getItem('hide');

	if (cookieChoice === 'true') {
		const cookieMessage = document.querySelector('.section--cookies-compliance');
		cookieMessage.style.display = 'none';
		cookieMessage.remove();
	}

	cookieAccept.addEventListener('click', function () {
		const cookieMessage = document.querySelector('.section--cookies-compliance');
		cookieMessage.remove();
		localStorage.setItem('hide', 'true'); // store state in localStorage
	});
}

// cookieDecline.addEventListener('click', function () {
// 	const cookieMessage = document.querySelector('.section--cookies-compliance');
// 	cookieMessage.remove();
// 	localStorage.setItem('hide', 'true'); // store state in localStorage
// });

/* ============================================================
 * Kipsu Popup
 * ============================================================ */

// const closeKipsu = document.querySelector('#close-kipsu');
// const kipsuChoice = localStorage.getItem('kipsuClosed');

// if (kipsuChoice === 'true') {
// 	const kipsumobile = document.querySelector('#kipsu-mobile');
// 	const kipsudesktop = document.querySelector('#kipsu-desktop');
// 	const kipsucontainer = document.querySelector('#kipsu-container');

// 	kipsudesktop.style.display = 'none';
// 	kipsumobile.style.display = 'block';
// 	kipsucontainer.classList.remove('desktopView');
// 	kipsucontainer.classList.add('mobileView');
// }

// closeKipsu.addEventListener('click', function () {
// 	const kipsumobile = document.querySelector('#kipsu-mobile');
// 	const kipsudesktop = document.querySelector('#kipsu-desktop');
// 	const kipsucontainer = document.querySelector('#kipsu-container');

// 	kipsudesktop.style.display = 'none';
// 	kipsumobile.style.display = 'block';
// 	kipsucontainer.classList.remove('desktopView');
// 	kipsucontainer.classList.add('mobileView');
// 	localStorage.setItem('kipsuClosed', 'true');
// });

// When users clicks on Kipsu mobile, show Kipsu Desktop
// const kipsumobile = document.querySelector('#kipsu-mobile');

// kipsumobile.addEventListener('click', function () {
// 	// Kipsu - Desktop Container
// 	const kipsudesktop = document.querySelector('#kipsu-desktop');

// 	// Kipsu - Mobile Container
// 	const kipsumobile = document.querySelector('#kipsu-mobile');

// 	// Kipsu - Whole Container
// 	const kipsucontainer = document.querySelector('#kipsu-container');

// 	kipsucontainer.classList.add('desktopView');
// 	kipsucontainer.classList.remove('mobileView');
// 	kipsudesktop.style.display = 'block';
// 	kipsumobile.style.display = 'none';
// });

/* ============================================================
 * Back To Top Button
 * ============================================================ */

const scrolltop = document.querySelector('#scroll-top');

function myFunction() {
	if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
		scrolltop.style.display = 'block';
	} else {
		scrolltop.style.display = 'none';
	}
}

window.onload = function () {
	scrolltop.style.display = 'none';
};

window.onscroll = function () {
	myFunction();
};

scrolltop.addEventListener('click', function () {
	// For Safari
	document.body.scrollTop = 0;

	// For Chrome, Firefox, IE and Opera
	document.documentElement.scrollTop = 0;
});

/* ============================================================
 * Video Sliders Play Button
 * ============================================================ */

// To open Slider video in new window - NOT FINISHED
const carousel = document.querySelector('.section--carousel');

if (carousel !== null) {
	const slide = carousel.querySelectorAll('.slide');

	slide.forEach((button) => {
		button.addEventListener('click', function () {
			const openFullScreen = this.querySelector('#open-fullscreen');
			const slideVid = this.querySelector('.slide__video');
			slideVid.classList.toggle('openFullScreen');

			// When user clicks on White Play Button:
			openFullScreen.addEventListener('click', function () {
				/* ============================================================
				 * FULL Width Video Slides
				 * ============================================================ */

				// Find Modal & Show it
				this.parentElement.parentElement.parentElement.children[2].style.display = 'block';

				// Find the Carousel & Stop the slider animation:
				this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.remove(
					'active',
				);
				// Set Carousel Z-Index higher than everything else (500)
				this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
					'500';

				// Find Close Button, onclick:
				this.parentElement.parentElement.parentElement.children[2].children[0].children[0].onclick =
					function () {
						// Hide the Modal
						this.parentElement.parentElement.style.display = 'none';
						// Start the Slider animation again
						this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add(
							'active',
						);
						// Set Carousel Z-Index back to original (300)
						this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
							'300';
					};

				/* ============================================================
				 * HALF Width Video Slides
				 * ============================================================ */

				// Find Modal & show it
				this.parentElement.parentElement.parentElement.children[1].style.display = 'block';

				// Find the Carousel & Stop the slider animation:
				this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.remove(
					'active',
				);
				// Set Carousel Z-Index higher than everything else (500)
				this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
					'500';

				// Find Close Button, onclick:
				this.parentElement.parentElement.parentElement.children[1].children[0].children[0].onclick =
					function () {
						// Hide the Modal
						this.parentElement.parentElement.style.display = 'none';
						// Start the Slider animation again
						this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add(
							'active',
						);
						// Set Carousel Z-Index back to original (300)
						this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex =
							'300';
					};
			});
		});
	});
}

/* ============================================================
 * To Pause / Play Videos in Slider
 * ============================================================ */

const carousel2 = document.querySelector('.section--carousel');

if (carousel2 !== null) {
	const vidContainer = carousel2.querySelectorAll('.video_controls-container');

	vidContainer.forEach((button) => {
		button.addEventListener('click', function () {
			button.classList.toggle('clicked-me'); // video controls container

			const vid_controls = button.children[0];
			vid_controls.classList.toggle('playing');
			vid_controls.classList.toggle('stopped');

			if (vid_controls.classList.contains('playing')) {
				const control_pause = vid_controls.children[0];
				const control_play = vid_controls.children[1];
				const control_text = vid_controls.children[2];
				// // 1. Find video and play it
				button.parentElement.parentElement.querySelector('.slide__video').play();
				// // 2. Change text to play
				control_text.innerHTML = 'pause';
				// // 3. Show Pause SVG
				control_pause.style.display = 'block';
				// // 4. Pause Play SVG
				control_play.style.display = 'none';
			}

			if (vid_controls.classList.contains('stopped')) {
				const control_pause = vid_controls.children[0];
				const control_play = vid_controls.children[1];
				const control_text = vid_controls.children[2];
				// // 1. Find video and pause it
				button.parentElement.parentElement.querySelector('.slide__video').pause();
				// // 2. Change text to pause
				control_text.innerHTML = 'play';
				// // 3. Show Play SVG
				control_play.style.display = 'block';
				// // 4. Hide Pause SVG
				control_pause.style.display = 'none';
			}
		});
	});
}

/* ============================================================
 * Animations
 * ============================================================ */

/* eslint-disable */

function isElementInViewport(el, verticaloffset) {
    const win = jQuery(window);
    const viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    const bounds = el.offset();
    bounds.right = bounds.left + el.outerWidth();
    bounds.bottom = bounds.top + el.outerHeight();
    return !(
        viewport.right < bounds.left ||
        viewport.left > bounds.right ||
        viewport.bottom < bounds.top ||
        viewport.top > bounds.bottom
    );
}

function set_animation(el) {
    el.addClass('animate__animated');
    if (el.hasClass('down')) {
        el.addClass('animate__fadeInDown');
        el.removeClass('down');
    } else if (el.hasClass('left')) {
        el.addClass('animate__fadeInLeft');
        el.removeClass('left');
    } else if (el.hasClass('right')) {
        el.addClass('animate__fadeInRight');
        el.removeClass('right');
    } else if (el.hasClass('in')) {
        el.addClass('animate__fadeIn');
        el.removeClass('in');
    } else if (el.hasClass('up')) {
        el.addClass('animate__fadeInUp');
        el.removeClass('up');
    } else if (el.hasClass('stagger')) {
        el.addClass('animate__fadeInUp');
        el.removeClass('up');
    }
}

/* Trigger Scrolling Animation */
jQuery(window).on('scroll', function () {
    jQuery('.scrolling').each(function () {
        if (isElementInViewport(jQuery(this), 4500)) {
            jQuery(this).removeClass('scrolling');
            set_animation(jQuery(this));
        }
    });
});

setTimeout(function () {
    jQuery('.scrolling').each(function () {
        if (isElementInViewport(jQuery(this), 4500)) {
            jQuery(this).removeClass('scrolling');
            set_animation(jQuery(this));
        }
    });
}, 4500);

jQuery('.menu--main-mobile .menu-item-has-children').each(function () {
    const accordion = jQuery(this);
    accordion.find('.sub-menu').hide();
    accordion.find('.sub-menu').attr('data-collapsed', 'true');
    const parentLink = accordion.find('a');
    accordion.on('click', function () {
        const subMenu = accordion.find('.sub-menu');
        jQuery('.menu-item-has-children .sub-menu').slideUp();

        parentLink.on('click', function () {
            var url = jQuery(this).attr("href");
            jQuery(location).attr('href', url);
        });

        if (accordion.find('.sub-menu').attr('data-collapsed') === 'true') {
            subMenu.slideDown('slow', function () {
                console.log('yo')
            })
            accordion.find('.sub-menu').attr('data-collapsed', 'false')
        } else {
            subMenu.slideUp('slow', function () {
                console.log('yo')
            })
            accordion.find('.sub-menu').attr('data-collapsed', 'true')
        }
        return false;

    })
});

jQuery(document).ready(function () {
    // Add smooth scrolling to all links
    jQuery(".back_to_top--btn").on('click', function (event) {
        event.preventDefault();
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        jQuery('html').animate({
            scrollTop: jQuery('body').offset().top
        }, 1200);
    });
});

/* ============================================================
 * Accordion JS
 * ============================================================ */

jQuery('.cc_item .question').on('click', function (e) {
    e.preventDefault();
    const li = jQuery(this).parent();
    const answer = jQuery(this).siblings('.answer');
    const height = answer.children('div').outerHeight();

    jQuery('.cc_item >li').removeClass('open');
    li.toggleClass('open');
    answer.toggleClass('open');

    if (answer.hasClass('open')) {
        jQuery('.cc_item .answer').removeClass('open').css('max-height', '');
        answer.addClass('open');
        answer.css('max-height', `${height}px`);
        jQuery('.cc_item >li .question .btn .icon_plus').css('opacity', '1');
        jQuery('.cc_item >li .question .btn .icon_minus').css('opacity', '0');
        jQuery(this).find('.icon_plus').css('opacity', '0');
        jQuery(this).find('.icon_minus').css('opacity', '1');

        const title = jQuery(this).find('.title_store_container .title');

        if (title.has("a")) {
            const linkText = jQuery(this).find('a').attr("href");

            title.on('click', function (e) {
                window.location.href = linkText;
            });
        }

    } else {
        jQuery('.cc_item >li').removeClass('open');
        answer.css('max-height', '');
        jQuery(this).find('.icon_plus').css('opacity', '1');
        jQuery(this).find('.icon_minus').css('opacity', '0');
    }

});

jQuery('.cc_item .question').on('keydown', function (e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        const li = jQuery(this).parent();
        const answer = jQuery(this).siblings('.answer');
        const height = answer.children('div').outerHeight();

        jQuery('.cc_item >li').removeClass('open');
        li.toggleClass('open');
        answer.toggleClass('open');

        if (answer.hasClass('open')) {
            jQuery('.cc_item .answer').removeClass('open').css('max-height', '');
            answer.addClass('open');
            answer.css('max-height', `${height}px`);
            jQuery('.cc_item >li .question .btn .icon_plus').css('opacity', '1');
            jQuery('.cc_item >li .question .btn .icon_minus').css('opacity', '0');
            jQuery(this).find('.icon_plus').css('opacity', '0');
            jQuery(this).find('.icon_minus').css('opacity', '1');

            const title = jQuery(this).find('.title_store_container .title');

            if (title.has("a")) {
                const linkText = jQuery(this).find('a').attr("href");

                title.on('click', function (e) {
                    window.location.href = linkText;
                });
            }

        } else {
            jQuery('.cc_item >li').removeClass('open');
            answer.css('max-height', '');
            jQuery(this).find('.icon_plus').css('opacity', '1');
            jQuery(this).find('.icon_minus').css('opacity', '0');
        }
    }
});

jQuery(window).on('resize', function () {
    jQuery('.cc_item .question').each(function () {
        const answer = jQuery(this).siblings('.answer');
        const height = answer.children('div').outerHeight();
        if (answer.hasClass('open')) {

            answer.css('max-height', `${height}px`);


        }
    })
})

/* ============================================================
 * Gift Cards dropdown
 * ============================================================ */

jQuery(document).on('ready', function () {
    jQuery('select').niceSelect();
});

jQuery('#cards').change(function () {
    if (jQuery(this) != 'none') {
        window.location = jQuery(this).val();
    }
});

jQuery('.store__dropdown ul').hide();

jQuery('.store__dropdown').on('click', function () {
    jQuery(this).toggleClass('open');
    jQuery(this).find('ul').slideToggle();
});

/* ============================================================
 * PL Videos
 * ============================================================ */

jQuery('.section--video .video__container .video__overlay .button').on('click', function (e) {
    e.preventDefault();
    // Find the overlay and hide it
    jQuery(this).parent().css('display', 'none');
    // Find the video and play it
    jQuery(this).parent().siblings().trigger('play');
    // Find the video and show video controls
    jQuery(this).parent().siblings().attr('controls', '');
});

/* ============================================================
 * Store Offers - Category Dropdown
 * ============================================================ */

jQuery('.sf-field-taxonomy-categories h4').on('click', function (e) {
    e.preventDefault();

    // When click on heading, show list items
    jQuery(this).siblings().children().find('.list').slideToggle('open');

    // Add a class so we can keep track of if it open or not
    jQuery(this).toggleClass('isOpen');

    // Change the icon to line
    jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');

    if (jQuery(this).hasClass('isOpen')) {
        // Dropdown is open, show the line icon
        jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');
    } else {
        // Dropdown is closed, show the arrow icon
        jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="6.704" viewBox="0 0 11.995 6.704"><g id="Component_1_113" data-name="Component 1 – 113" transform="translate(0.354 0.354)"><path id="Path_27" data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></g></svg>');
    }

    // When user has chosen a filter, close the filter menu
    jQuery('.sf-field-taxonomy-categories .list li').on('click', function (e) {
        jQuery(this).parent().css('display', 'none');
    });
});

// Insert SVG after DropDown Title
jQuery('.sf-field-taxonomy-categories h4').after(function () {
    return '<div class="toggle-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="6.704" viewBox="0 0 11.995 6.704"><g id="Component_1_113" data-name="Component 1 – 113" transform="translate(0.354 0.354)"><path id="Path_27" data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></g></svg></div>';
});

/* ============================================================
 * Store Offers - Taxonomy Dropdown
 * ============================================================ */

jQuery('.sf-field-taxonomy-stores h4').on('click', function (e) {
    e.preventDefault();

    // When click on heading, show list items
    jQuery(this).siblings().children().find('.list').slideToggle('open');

    // Add a class so we can keep track of if it open or not
    jQuery(this).toggleClass('isOpen');

    // Change the icon to line
    jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');

    if (jQuery(this).hasClass('isOpen')) {
        // Dropdown is open, show the line icon
        jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');
    } else {
        // Dropdown is closed, show the arrow icon
        jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="6.704" viewBox="0 0 11.995 6.704"><g id="Component_1_113" data-name="Component 1 – 113" transform="translate(0.354 0.354)"><path id="Path_27" data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></g></svg>');
    }

    // When user has chosen a filter, close the filter menu
    jQuery('.sf-field-taxonomy-stores .list li').on('click', function (e) {
        jQuery(this).parent().css('display', 'none');
    });
});

jQuery('.sf-field-taxonomy-stores h4').on('keydown', function (e) {
    if (e.keyCode === 13) {
        e.preventDefault();

        // When click on heading, show list items
        jQuery(this).siblings().children().find('.list').slideToggle('open');

        // Add a class so we can keep track of if it open or not
        jQuery(this).toggleClass('isOpen');

        // Change the icon to line
        jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');

        if (jQuery(this).hasClass('isOpen')) {
            // Dropdown is open, show the line icon
            jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.575" height="1" viewBox="0 0 11.575 1"><path id="Path_361" data-name="Path 361" d="M32,31.425H20.425" transform="translate(-20.425 -30.925)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></svg>');
        } else {
            // Dropdown is closed, show the arrow icon
            jQuery(this).parent().find('.toggle-arrow').empty().append('<svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="6.704" viewBox="0 0 11.995 6.704"><g id="Component_1_113" data-name="Component 1 – 113" transform="translate(0.354 0.354)"><path id="Path_27" data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></g></svg>');
        }

        // When user has chosen a filter, close the filter menu
        jQuery('.sf-field-taxonomy-stores .list li').on('click', function (e) {
            jQuery(this).parent().css('display', 'none');
        });
    }
});

// Insert SVG after DropDown Title
jQuery('.sf-field-taxonomy-stores h4').after(function () {
    return '<div class="toggle-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="6.704" viewBox="0 0 11.995 6.704"><g id="Component_1_113" data-name="Component 1 – 113" transform="translate(0.354 0.354)"><path id="Path_27" data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/></g></svg></div>';
});

/* eslint-enable */

const filtersContainerLabel = document.querySelectorAll('.filters__container label');

if (filtersContainerLabel !== null) {
	filtersContainerLabel.forEach((button) => {
		button.addEventListener('click', function () {
			button.parentElement.classList.toggle('active');
		});
	});
}

const overlay = document.querySelector('.overlay');

const gcWrapper = document.querySelector(
	'.other-gc__filters-mobile .other-gc__filter .other-gc__wrapper',
);

const closeCardsSVG = document.querySelector('#closeCardsSVG');

// const popup_kipsu = document.querySelector('.section--popups .kipsu');
const popup_scrollTop = document.querySelector('.section--popups .back_to_top');

if (gcWrapper !== null) {
	gcWrapper.addEventListener('click', function () {
		gcWrapper.classList.add('active');
		overlay.classList.add('active');
		closeCardsSVG.classList.add('active');
		// popup_kipsu.style.display = 'none';
		popup_scrollTop.style.display = 'none';
	});
}

if (closeCardsSVG !== null) {
	closeCardsSVG.addEventListener('click', function () {
		gcWrapper.classList.remove('active');
		overlay.classList.remove('active');
		closeCardsSVG.classList.remove('active');
		// popup_kipsu.style.display = 'block';
		popup_scrollTop.style.display = 'block';
	});
}

/* ============================================================
 * Forms
 * ============================================================ */

/* eslint-disable */

jQuery('.section--forms .forms--wrapper .gform_wrapper form .validation_error').text("Please ensure all required fields are filled before submitting");
jQuery('.section--newsletter .container--newsletter .form_container .gform_wrapper form .validation_error').text("Please ensure all required fields are filled before submitting");
// the ui-datepicker was showing at the bottom of this page, below the footer,
// until the user opened the datepicker. So it's hidden until a user
// clicks to open it
jQuery('.datepicker').on('click', function (e) {
    jQuery('.ui-datepicker ').css('display', 'block');
});

/* this scrolls the page up to the top of the anchor link */
jQuery(document).on('click', 'a[href^="#"]', function (event) {
    // event.preventDefault();
    if (!jQuery(this).attr('href')) {

        jQuery('html, body').animate({
            scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 100
        }, 500);
    }
});

/* eslint-enable */

// Hide Store Offers category if empty
const select = document.querySelector('.sf-field-taxonomy-categories');
if (select) {
	const catTax = select.querySelector('select');
	if (catTax) {
		const catOption = catTax.querySelectorAll('option');
		if (!catOption) {
			select.style.display = 'none';
		} else if (catOption.length === 1 && catOption[0].innerText === 'All Categories') {
			select.style.display = 'none';
		}
	}
}

// Parking Module

const parkingAvailabilityModule = document.querySelector('.parking-capacity-module .parking');

const percentInformationModule = document.querySelector(
	'.parking-capacity-module .parking .parking__information',
);

if (parkingAvailabilityModule) {
	parkingAvailabilityModule.addEventListener('click', function () {
		percentInformationModule.classList.toggle('active');
		parkingAvailabilityModule.classList.toggle('active');
	});
}

/* ============================================================
 * Toggles
 * ============================================================ */

const hoverNav = document.querySelectorAll('.menu--main .menu-item');
const shopNav = document.querySelector('#shopNav');
const shopToggle = document.querySelector('#shopToggle');
const alertBanner = document.querySelector('.header--alert');
const alertCloseButton = alertBanner.querySelector('.button--close');
const hoursAll = document.querySelector('#hoursAll');

// form close button
// const formCloseButton = alertBanner.querySelector('.button--close');

toggleElement('#hoursToggle', '#hoursAll', 'menu--active', true);
toggleElement('#hoursToggleMobile', '.header__hours--all', 'menu--active');
toggleElement('#hoursToggleMobile', '.header__hours .button--capacity', 'capacity--active');

// toggleElement('#shopToggle', '#shopNav', 'menu--active');

toggleElement('#mobileShopToggle', '#mobileShopNav', 'menu--active');
toggleElement('#shopNavClose', '#mobileShopNav', 'menu--active');
// toggleElement('#mallMenuToggle', '.menu--main-mobile', 'mobile-menu--active');
// toggleElement('#mallMenuToggle', '#mallMenuToggle svg', 'mobile-menu--active');
toggleElement('#mallMenuToggle', '.header--mobile-extra', 'active');
toggleElement('#mallMenuToggle', '#mobileShopNav', 'menu--active');
toggleElement('#capacityToggle', '#mobileCapacity', 'capacity--active');
toggleElement('#mobileSearchToggle', '.header--search-mobile', 'search--open');
toggleElement('#desktopSearchToggle', '.header--search-mobile', 'search--open');
toggleElement('#mobileSearchClose', '.header--search-mobile', 'search--open');

// Need to refactor this for later
// this is the menu for Malls, not shop
const mobileMenuMall = document.querySelector('.menu--main-mobile');
const subMenuButtons = mobileMenuMall.querySelectorAll('.menu-item-has-children');

/* ============================================================
 * Alert Banner
 * ============================================================ */

const alertChoice = localStorage.getItem('alertClosed');

if (alertChoice === 'true') {
	removeElement(alertBanner);
	mobileMenuMall.classList.remove('alert__banner-spacing');
}

if (alertCloseButton) {
	alertCloseButton.addEventListener('click', function () {
		removeElement(alertBanner);
		mobileMenuMall.classList.remove('alert__banner-spacing');
		localStorage.setItem('alertClosed', 'true');
	});
}

subMenuButtons.forEach((button) => {
	button.addEventListener('click', function (e) {
		e.preventDefault();
		const allSubMenus = mobileMenuMall.querySelectorAll('.sub-menu');
		const subMenu = this.querySelector('.sub-menu');

		if (subMenu.classList.contains('menu--active')) {
			subMenu.classList.remove('menu--active');
			this.classList.remove('menu--active');
		} else {
			allSubMenus.forEach((menu) => {
				menu.classList.remove('menu--active');
			});

			subMenuButtons.forEach((button) => {
				button.classList.remove('menu--active');
			});

			this.classList.add('menu--active');

			subMenu.classList.add('menu--active');
		}
	});
});

/* ============================================================
 * Sticky Menu on Scroll
 * ============================================================ */

const header = document.querySelector('#header');
const mobileHeader = document.querySelector('#mobile-header');
const menuMainMobile = document.querySelector('.menu--main-mobile');
const headerMenuMobile = document.querySelector('.header__menu-mobile');
const headerSearch = document.querySelector('.header--search-mobile');
const mobileSearchToggle = document.querySelector('#mobileSearchToggle');

// const mallMenuToggle = document.querySelector('#mallMenuToggle');

function amountscrolled() {
	const scrollTop =
		window.pageYOffset ||
		(document.documentElement || document.body.parentNode || document.body).scrollTop;

	if (scrollTop >= '147') {
		header.classList.add('sticky');
		menuMainMobile.classList.add('scrolling-top-position');
		headerMenuMobile.classList.add('scrolling-top-position');
		if (shopNav) {
			shopNav.classList.add('nav--scrolling-top');
		}
		headerSearch.classList.add('search_make-sticky');
		mobileSearchToggle.classList.add('make-sticky');
	} else {
		header.classList.remove('sticky');
		menuMainMobile.classList.remove('scrolling-top-position');
		headerMenuMobile.classList.remove('scrolling-top-position');
		if (shopNav) {
			shopNav.classList.remove('nav--scrolling-top');
		}
		headerSearch.classList.remove('search_make-sticky');
		mobileSearchToggle.classList.remove('make-sticky');
	}

	if (scrollTop >= '190') {
		mobileHeader.classList.add('sticky');
		menuMainMobile.classList.add('scrolling-top-position');
		headerMenuMobile.classList.add('scrolling-top-position');
		if (shopNav) {
			shopNav.classList.add('nav--scrolling-top');
		}
		headerSearch.classList.add('search_make-sticky');
		mobileSearchToggle.classList.add('make-sticky');
	} else {
		mobileHeader.classList.remove('sticky');
		menuMainMobile.classList.remove('scrolling-top-position');
		headerMenuMobile.classList.remove('scrolling-top-position');
		if (shopNav) {
			shopNav.classList.remove('nav--scrolling-top');
		}
		headerSearch.classList.remove('search_make-sticky');
		mobileSearchToggle.classList.remove('make-sticky');
	}
}

window.addEventListener(
	'scroll',
	function () {
		amountscrolled();
	},
	false,
);

/* ============================================================
 * Mobile header search
 * ============================================================ */

const mobileSearchClose = document.querySelector('#mobileSearchClose');

mobileSearchClose.addEventListener('click', function () {
	this.parentNode.classList.remove('search--open');
});

// handleClickOutside('#shopNav', 'menu--active', ['#shopToggle', '#mobileShopToggle']);

// this function gets rid of the hover of a different menu

hoverNav.forEach((nav) => {
	nav.addEventListener('mouseover', function () {
		if (shopNav) {
			shopNav.classList.remove('menu--active');
		}
		if (shopToggle) {
			shopToggle.classList.remove('menu--active');
		}
	});
});

const shopToggleMobile = document.querySelector('#mobileShopToggle');
const mallMenuToggle = document.querySelector('#mallMenuToggle');

if (shopToggleMobile) {
	shopToggleMobile.addEventListener('click', function () {
		const svgs = mallMenuToggle.querySelectorAll('svg');
		mallMenuToggle.classList.remove('mobile-menu--active');
		svgs.forEach((svg) => svg.classList.remove('mobile-menu--active'));
	});
}

/* ============================================================
 * Shop Menu
 * ============================================================ */

window.addEventListener('mousemove', function () {
	if (shopNav) {
		if (shopNav.classList.contains('menu--active') && !shopNav.matches(':hover')) {
			let hoverTimeout;
			setTimeout(() => {
				if (shopNav.matches(':hover')) {
					clearTimeout(hoverTimeout);
				} else {
					hoverTimeout = setTimeout(() => {
						shopNav.classList.remove('menu--active');
						if (shopToggle) {
							shopToggle.classList.remove('menu--active');
						}
					}, 1500);
				}
			}, 4500);
		}
	}

	if (hoursAll.classList.contains('menu--active') && !hoursAll.matches(':hover')) {
		let hoverTimeout;
		setTimeout(() => {
			if (hoursAll.matches(':hover')) {
				clearTimeout(hoverTimeout);
			} else {
				hoverTimeout = setTimeout(() => {
					hoursAll.classList.remove('menu--active');
				}, 1500);
			}
		}, 4500);
	}
});

const hoursToggle = document.querySelector('#hoursToggleMobile');

const capacityToggle = document.querySelector('#capacityToggle');

const mobileCapacity = document.querySelector('#mobileCapacity');

const mobileHoursAll = document.querySelector('#hoursToggleMobile .header__hours--all');

if (hoursToggle) {
	hoursToggle.addEventListener('click', function () {
		if (mobileCapacity.classList.contains('capacity--active')) {
			mobileCapacity.classList.remove('capacity--active');
			capacityToggle.classList.remove('capacity--active');
		}
	});
}

if (capacityToggle) {
	capacityToggle.addEventListener('click', function () {
		if (mobileHoursAll.classList.contains('menu--active')) {
			mobileHoursAll.classList.remove('menu--active');
			const hoursBtn = hoursToggle.querySelector('.button--capacity');
			hoursBtn.classList.remove('capacity--active');
		}
	});
}

(function (win, dom) {
	win.addEventListener('load', function () {
		if (!(sd && is(sd) === 'object')) {
			return;
		}

		const newsletterFormElement = dom.querySelector('.form_container form');
		if (!newsletterFormElement) {
			return;
		}

		if (dom.body.classList.contains('home') && !newsletterFormElement) {
			return;
		}

		newsletterFormElement.addEventListener('submit', function (event) {
			// event.preventDefault();
			const formData = new FormData(event.target);
			const firstName = formData.get('input_1');
			const lastName = formData.get('input_2');
			const email = formData.get('input_3');
			const name = `${firstName} ${lastName}`;
			const userData = {
				email,
				firstName,
				lastName,
				name,
				userId: '',
				properties: {
					event: 'newsletter-signup',
				},
			};
			sd.identify('', userData);
			sd.track('registration', {
				...userData,
				optIn: true,
			});
		});
	});
})(window, window.document);

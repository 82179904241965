/* ============================================================
 * To Pause / Play Videos
 * ============================================================ */

const shoppingGuideVideos = document.querySelectorAll('.sg--video-module');

if (shoppingGuideVideos.length > 0) {
	shoppingGuideVideos.forEach((shoppingGuideVideo) => {
		const vidContainer = shoppingGuideVideo.querySelectorAll('.video_controls-container');

		vidContainer.forEach((button) => {
			button.addEventListener('click', function () {
				button.classList.toggle('clicked-me'); // video controls container

				const vid_controls = button.children[0];
				vid_controls.classList.toggle('playing');
				vid_controls.classList.toggle('stopped');

				if (vid_controls.classList.contains('playing')) {
					const control_pause = vid_controls.children[0];
					const control_play = vid_controls.children[1];
					const control_text = vid_controls.children[2];

					// 1. Find video and play it
					shoppingGuideVideo.querySelector('.sg-module--video').play();
					// 2. Change text to pause
					control_text.innerHTML = 'Pause';
					// 3. Show Pause SVG
					control_pause.style.display = 'block';
					// 4. Hide Play SVG
					control_play.style.display = 'none';
				}

				if (vid_controls.classList.contains('stopped')) {
					const control_pause = vid_controls.children[0];
					const control_play = vid_controls.children[1];
					const control_text = vid_controls.children[2];

					// 1. Find video and pause it
					shoppingGuideVideo.querySelector('.sg-module--video').pause();
					// 2. Change text to play
					control_text.innerHTML = 'Play';
					// 3. Show Play SVG
					control_play.style.display = 'block';
					// 4. Hide Pause SVG
					control_pause.style.display = 'none';
				}
			});
		});
	});
}

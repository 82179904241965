// Toggle Element
// Example: Used to add an active class to menu so that it'll slide open

// Takes three parameters, gets the selector and the class to toggle
const toggleElement = (buttonSelector, toggledElement, className, hover = false) => {
	const button = document.querySelector(buttonSelector);
	const elements = document.querySelectorAll(toggledElement);

	if (button && elements) {
		button.addEventListener('click', function () {
			elements.forEach((element) => {
				if (element.classList.contains(className)) {
					element.classList.remove(className);
					button.classList.remove(className);
				} else {
					element.classList.add(className);
					button.classList.add(className);

					// menu refactoring
					if (toggledElement === '#mobileShopNav') {
						const other = document.querySelector('.menu--main-mobile');
						other.classList.remove('mobile-menu--active');
					} else if (toggledElement === '.menu--main-mobile') {
						const other = document.querySelector('#mobileShopNav');
						other.classList.remove('menu--active');
					}
				}
			});
		});

		button.addEventListener('focus', function () {
			button.addEventListener('keyup', function (e) {
				if (e.keyCode === 13 || e.keyCode === 32) {
					elements.forEach((element) => {
						if (element.classList.contains(className)) {
							element.classList.remove(className);
							button.classList.remove(className);
						} else {
							element.classList.add(className);
							button.classList.add(className);

							// menu refactoring
							if (toggledElement === '#mobileShopNav') {
								const other = document.querySelector('.menu--main-mobile');
								other.classList.remove('mobile-menu--active');
							} else if (toggledElement === '.menu--main-mobile') {
								const other = document.querySelector('#mobileShopNav');
								other.classList.remove('menu--active');
							}
						}
					});
				}
			});
		});

		if (hover) {
			button.addEventListener(
				'mouseover',
				function () {
					setTimeout(() => {
						elements.forEach((element) => {
							if (element.classList.contains(className)) {
								button.classList.remove(className);
							} else {
								setTimeout(() => {
									element.classList.add(className);
									button.classList.add(className);
								}, 200);
							}
						});
					});
				},
				300,
			);
			elements.forEach((element) => {
				element.addEventListener('mouseleave', function () {
					if (element.classList.contains(className)) {
						setTimeout(() => {
							element.classList.remove(className);
							button.classList.remove(className);
						}, 200);
					}
				});
			});
		}
	}
};

export default toggleElement;

import config from '../config';
// import { jwtTokenIsNotValid } from '../lib/utils';
import * as browserStorage from '../lib/browserStorage';
import 'formdata-polyfill';

(function (win, dom) {
	/**
	 * Click listener
	 *
	 * @param {MouseEvent} event Click event
	 */
	const clickListener = function (event) {
		/** @type {HTMLElement} */
		const element = event.target;
		if (element.tagName === 'BUTTON' && element.classList.contains('popup--close')) {
			const closestSelector = element.getAttribute('data-closest') || '';
			/** @type {HTMLElement|null} */
			const closestElement = element.closest(`.${closestSelector}`);
			if (!closestElement) {
				return;
			}

			/** @type {HTMLDivElement|null} */
			const accountPopupsElement = dom.querySelector('.account--popups');
			if (!accountPopupsElement) {
				return;
			}

			switch (closestSelector) {
				case 'newsletter':
					{
						const nextSelector = element.getAttribute('data-new-user');
						/** @type {HTMLElement|null} */
						const nextElement = accountPopupsElement.querySelector(`.${nextSelector}`);
						if (!nextElement) {
							return;
						}

						win.setTimeout(function () {
							nextElement.style.removeProperty('display');
						}, 1000);
					}
					break;

				default:
					console.log('This is the default');
					break;
			}

			closestElement.style.display = 'none';
			return;
		}

		if (element.tagName === 'BUTTON' && element.hasAttribute('data-link')) {
			event.preventDefault();

			/** @type {HTMLButtonElement|null} */
			const linkedElement = dom.querySelector(`#${element.getAttribute('data-link')}`);
			if (linkedElement) {
				linkedElement.click();
			}

			return;
		}

		if (
			element.tagName === 'BUTTON' &&
			element.hasAttribute('data-hide') &&
			element.hasAttribute('data-show')
		) {
			event.preventDefault();

			const formElement = element.closest('form');
			if (!formElement) {
				return;
			}

			const submitElement = formElement.querySelector('input[type=submit]');
			const step1ContainerElement = formElement.querySelector('#register-step-1');
			const requiredElements = step1ContainerElement.querySelectorAll('input[required]');
			for (let i = 0, len = requiredElements.length; i < len; i++) {
				const requiredElement = requiredElements[i];
				const requiredElementType = requiredElement.getAttribute('type').toLowerCase();
				if (requiredElementType === 'email' || requiredElementType === 'text') {
					const requiredElementValue = requiredElement.value.trim();
					if (requiredElementValue === '') {
						submitElement.click();
						return;
					}
				} else if (requiredElementType === 'checkbox' && !requiredElement.checked) {
					submitElement.click();
					return;
				}
			}

			const accountPopupElement = element.closest('.account--popup');
			const showElement = accountPopupElement?.querySelector(
				`#${element.getAttribute('data-show')}`,
			);
			const hideElement = accountPopupElement?.querySelector(
				`#${element.getAttribute('data-hide')}`,
			);
			if (showElement) {
				showElement.style.display = 'grid';
				accountPopupElement.classList.add('step-two');
			}

			if (hideElement) {
				hideElement.style.display = 'none';
			}

			return;
		}

		if (
			element.tagName === 'A' &&
			element.classList.contains('forgot-password') &&
			element.hasAttribute('data-action')
		) {
			event.preventDefault();

			const formElement = element.closest('form');
			if (!formElement) {
				return;
			}

			const newFormAction = element.getAttribute('data-action') || '#';
			formElement.setAttribute('action', newFormAction);
			formElement.setAttribute('data-form-action', element.getAttribute('data-form-action'));
			const newSubmitText = element.getAttribute('data-reset-text') || 'Send Reset Link';
			const submitElement = formElement.querySelector('input[type=submit]');
			if (submitElement) {
				submitElement.setAttribute('value', newSubmitText);
			}

			const passwordElement = formElement.querySelector('input[type=password]');
			if (passwordElement) {
				passwordElement.remove();
			}

			element.remove();
		}
	};

	/**
	 * DOM content loaded
	 */
	const domContentLoadedListener = function () {
		/** @type {HTMLDivElement|null} */
		const accountPopupsElement = dom.querySelector('.account--popups');
		if (!accountPopupsElement) {
			return;
		}

		/** @type {string|undefined|null} */
		const cookiePopups = browserStorage.getCookieItem(config.popups);
		/** @type {string[]|null|undefined} */
		const popups = JSON.parse(cookiePopups ?? '[]');
		if (!Array.isArray(popups)) {
			return;
		}

		if (!popups.length) {
			const newsletterElement = accountPopupsElement.querySelector('div.newsletter');
			if (!newsletterElement) {
				return;
			}

			newsletterElement.style.removeProperty('display');
			browserStorage.storeCookieItem(config.popups, JSON.stringify(['newsletter']));
		} else if (!popups.includes('login')) {
			const existingUserElement = accountPopupsElement.querySelector('div.existing--user');
			if (!existingUserElement) {
				return;
			}

			popups.push('login');
			existingUserElement.style.removeProperty('display');
			browserStorage.storeCookieItem(config.popups, JSON.stringify(popups));
		}
	};

	dom.addEventListener('click', clickListener);
	win.addEventListener('DOMContentLoaded', domContentLoadedListener);
})(window, window.document);

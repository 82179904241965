/* ============================================================
 * Sliders
 * ============================================================ */

/* eslint-disable */

document.addEventListener('DOMContentLoaded', function () {
    Array.from(document.querySelectorAll('.section--image-gallery'))
        .filter((imageGallery) => !imageGallery.classList.contains('tabbed'))
        .forEach((imageGallery) => {
            const standardSlider = imageGallery.querySelector('.main-carousel');
            if (window.Flickity && standardSlider) {
                let standardFlkty = new Flickity(standardSlider, {
                    cellAlign: 'left',
                    contain: true,
                    wrapAround: true,
                    fullscreen: true,
                    draggable: false,
                    on: {
                        ready() {
                            setTimeout(function () {
                                standardFlkty.resize();
                            }, 100);
                        },
                    },
                });
            } else {
                console.error('Flickity library or carousel element is not available');
            }
        })
}, false);
/* eslint-enable */

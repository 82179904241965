document.addEventListener('DOMContentLoaded', function () {
	const blogGrids = document.querySelectorAll('.sg--blog-product-grid');
	const cardsToShow = 6;

	blogGrids.forEach(function (grid) {
		const showMoreButton = grid.querySelector('#show-more-button');
		const hiddenCards = grid.querySelectorAll('.card.hidden');

		if (showMoreButton && hiddenCards.length > 0) {
			showMoreButton.addEventListener('click', function () {
				let revealedCount = 0;

				hiddenCards.forEach(function (card) {
					if (card.classList.contains('hidden') && revealedCount < cardsToShow) {
						card.classList.remove('hidden');
						revealedCount++;
					}
				});

				// Hide the button if there are no more hidden cards
				if (grid.querySelectorAll('.card.hidden').length === 0) {
					showMoreButton.style.display = 'none';
				}
			});
		}
	});
});

// For the Follow Us Button - open on Keyboard Enter
const socialMenu = document.querySelector('.menu--social');
if (socialMenu) {
	socialMenu.addEventListener('keydown', function (e) {
		if (e.key === 'Enter') {
			e.stopPropagation();
			this.classList.toggle('active');
		}
	});
}

// If users click anywhere except for the Follow Us Button, clost the Follow Us Button
const html = document.querySelector('html');
html.addEventListener(
	'click',
	function () {
		if (socialMenu) {
			socialMenu.classList.remove('active');
		}
	},
	false,
);
html.addEventListener(
	'keydown',
	function (e) {
		if (e.key === 'Enter') {
			e.stopPropagation();
			if (socialMenu) {
				socialMenu.classList.remove('active');
			}
		}
	},
	false,
);
if (socialMenu) {
	socialMenu.addEventListener(
		'click',
		function (ev) {
			ev.stopPropagation();
		},
		false,
	);
}

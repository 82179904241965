/* eslint-disable consistent-return */
import Flickity from 'flickity';

/**
 * Initialize flickity
 *
 * @param {HTMLElement} elem HTML element
 * @returns {Flickity|undefined}
 */
function initializeFlickity(elem) {
	if (!(elem instanceof HTMLElement)) {
		return;
	}

	return new Flickity(elem, {
		// Flickity options
		cellAlign: 'left',
		contain: true,
		wrapAround: true,
		fullscreen: true,
		draggable: false,
	});
}

// Function to use tabs
// Takes a paremeter of parent to get each tab
// Parent is the parent selector!
function openTabs(parent) {
	if (!(parent instanceof HTMLElement)) {
		return;
	}

	/** @type {HTMLDivElement} */
	const activeTabContent = parent.querySelector('.tabcontent.active');
	const carousels = activeTabContent.querySelectorAll('.main-carousel');
	carousels.forEach((carousel) => {
		initializeFlickity(carousel);
	});
	// if (activeTabContent) {
	// 	initializeFlickity(activeTabContent.querySelector('.main-carousel'));
	// }

	const tabLinks = parent.querySelectorAll('.button--tab');
	tabLinks.forEach((link) => {
		link.addEventListener('click', function (e) {
			e.preventDefault();

			Array.from(parent.querySelectorAll('.tabcontent'))
				.map((element) => {
					const flickityElement = element.querySelector(
						'.main-carousel.flickity-enabled',
					);
					if (flickityElement) {
						const flickity = initializeFlickity(flickityElement);
						flickity.remove();
					}

					return element;
				})
				.filter((element) => element.classList.contains('active'))
				.forEach((element) => element.classList.remove('active'));
			Array.from(parent.querySelectorAll('.button--tab'))
				.filter((element) => element.classList.contains('active'))
				.forEach((element) => element.classList.remove('active'));

			/** @type {HTMLButtonElement} */
			const element = e.target;
			element.classList.add('active');
			const tabId = element.getAttribute('data-tab-id');
			/** @type {HTMLDivElement|null} */
			const contentElement = document.querySelector(`#${tabId}`);
			/** @type {HTMLDivElement|null} */

			const mainCarouselElement = contentElement?.querySelectorAll('.main-carousel');

			if (contentElement) {
				contentElement.classList.remove('active');
				contentElement.classList.add('active');
			}

			if (mainCarouselElement) {
				mainCarouselElement.forEach((mainCarouselElement) => {
					initializeFlickity(mainCarouselElement);
				});
			}

			const refresh = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${tabId}`;
			window.history.pushState({ path: refresh }, '', refresh);
		});
	});
}

export default openTabs;

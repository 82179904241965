/*
   Carousel Prototype
   Eric Eggert for W3C
*/

// import wrapFirstWord from '../components/wrapFirstWord';

const CTCarousel = function () {
	// Initial variables
	let carousel;
	let slides;
	let index;
	let slidenav;
	let settings;
	let timer;
	let setFocus;
	let animationSuspended;

	// Helper function: Iterates over an array of elements
	function forEachElement(elements, fn) {
		for (let i = 0; i < elements.length; i++) fn(elements[i], i);
	}

	// Helper function: Remove Class
	function removeClass(el, className) {
		if (el.classList) {
			el.classList.remove(className);
		} else {
			el.className = el.className.replace(
				new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
				' ',
			);
		}
	}

	// Helper function: Test if element has a specific class
	function hasClass(el, className) {
		if (el.classList) {
			return el.classList.contains(className);
		}
		return new RegExp(`(^| )${className}( |$)`, 'gi').test(el.className);
	}

	// Initialization for the carousel
	// Argument: set = an object of settings
	// Possible settings:
	// id <string> ID of the carousel wrapper element (required).
	// slidenav <bool> If true, a list of slides is shown.
	// animate <bool> If true, the slides can be animated.
	// startAnimated <bool> If true, the animation begins
	//                        immediately.
	//                      If false, the animation needs
	//                        to be initiated by clicking
	//                        the play button.
	function init(set) {
		// Make settings available to all functions
		settings = set;
		// Select the element and the individual slides
		carousel = document.querySelector(settings.id);
		// slides = carousel.querySelectorAll('.slide');
		slides = carousel ? carousel.querySelectorAll('.slide') : [];

		// Function to set a slide the current slide
		function setSlides(new_current, setFocusHere, transition, announceItemHere) {
			// Focus, transition and announce Item are optional parameters.
			// focus denotes if the focus should be set after the
			// carousel advanced to slide number new_current.
			// transition denotes if the transition is going into the
			// next or previous direction.
			// If announceItem is set to true, the live region’s text is changed (and announced)
			// Here defaults are set:

			setFocus = typeof setFocusHere !== 'undefined' ? setFocusHere : false;
			const doTransition = typeof transition !== 'undefined' ? transition : 'none';
			const doAnnounceItem =
				typeof announceItemHere !== 'undefined' ? announceItemHere : false;

			const newCurrentSlide = parseFloat(new_current);

			const { length } = slides;
			let new_next = newCurrentSlide + 1;
			let new_prev = newCurrentSlide - 1;

			// If the next slide number is equal to the length,
			// the next slide should be the first one of the slides.
			// If the previous slide number is less than 0.
			// the previous slide is the last of the slides.
			if (new_next === length) {
				new_next = 0;
			} else if (new_prev < 0) {
				new_prev = length - 1;
			}

			// Reset slide classes
			for (let i = slides.length - 1; i >= 0; i--) {
				slides[i].className = 'slide';
			}

			// Add classes to the previous, next and current slide
			slides[new_next].className = `next slide${
				doTransition === 'next' ? ' in-transition' : ''
			}`;
			slides[new_next].setAttribute('aria-hidden', 'true');

			slides[new_prev].className = `prev slide${
				doTransition === 'prev' ? ' in-transition' : ''
			}`;
			slides[new_prev].setAttribute('aria-hidden', 'true');

			slides[newCurrentSlide].className = 'current slide';
			slides[newCurrentSlide].removeAttribute('aria-hidden');

			// Update the text in the live region which is then announced by screen readers.
			if (doAnnounceItem) {
				carousel.querySelector('.liveregion').textContent = `Item ${
					newCurrentSlide + 1
				} of ${slides.length}`;
			}

			// Update the buttons in the slider navigation to match the currently displayed  item
			if (settings.slidenav) {
				const buttons = carousel.querySelectorAll('.slidenav button[data-slide]');
				for (let j = buttons.length - 1; j >= 0; j--) {
					buttons[j].className = '';
					buttons[j].innerHTML = `<span class="visuallyhidden">News</span> ${j + 1}`;
				}
				buttons[newCurrentSlide].className = 'current';
				buttons[newCurrentSlide].innerHTML = `<span class="visuallyhidden">News</span> ${
					newCurrentSlide + 1
				} <span class="visuallyhidden">(Current Item)</span>`;
			}

			// Set the global index to the new current value
			index = newCurrentSlide;
		}

		// Function to advance to the next slide
		function nextSlide(announceItem) {
			const doAnnounceItem = typeof announceItem !== 'undefined' ? announceItem : false;

			const { length } = slides;
			let new_current = index + 1;

			if (new_current === length) {
				new_current = 0;
			}

			// If we advance to the next slide, the previous needs to be
			// visible to the user, so the third parameter is 'prev', not
			// next.
			setSlides(new_current, false, 'prev', doAnnounceItem);

			// If the carousel is animated, advance to the next
			// slide after 5s
			if (settings.animate) {
				timer = setTimeout(nextSlide, 10000);
			}
		}

		// Function to advance to the previous slide
		function prevSlide(announceItem) {
			const doAnnounceItem = typeof announceItem !== 'undefined' ? announceItem : false;

			const { length } = slides;
			let new_current = index - 1;

			// If we are already on the first slide, show the last slide instead.
			if (new_current < 0) {
				new_current = length - 1;
			}

			// If we advance to the previous slide, the next needs to be
			// visible to the user, so the third parameter is 'next', not
			// prev.
			setSlides(new_current, false, 'next', doAnnounceItem);
		}

		// Function to stop the animation
		function stopAnimation() {
			clearTimeout(timer);
			settings.animate = false;
			animationSuspended = false;
			const _this = carousel.querySelector('[data-action]');
			_this.innerHTML = '<span class="visuallyhidden">Start Animation </span>▶';
			_this.setAttribute('data-action', 'start');
		}

		// Function to start the animation
		function startAnimation() {
			settings.animate = true;
			animationSuspended = false;
			timer = setTimeout(nextSlide, 5000);
			const _this = carousel.querySelector('[data-action]');
			_this.innerHTML = '<span class="visuallyhidden">Stop Animation </span>￭';
			_this.setAttribute('data-action', 'stop');
		}

		// Function to suspend the animation
		function suspendAnimation() {
			if (settings.animate) {
				clearTimeout(timer);
				settings.animate = false;
				animationSuspended = true;
			}
		}

		if (slides.length > 1) {
			carousel.className = `${settings?.additionalClasses}section section--carousel active carousel`;

			// Create unordered list for controls, and attach click events fo previous and next slide
			const ctrls = document.createElement('ul');

			ctrls.className = 'controls';
			ctrls.innerHTML =
				'<li class="btn-carousel">' +
				'<button type="button" class="button button--prev" aria-label="Previous Slide Button">' +
				'<svg width="17.492" height="11.995" viewBox="0 0 17.492 11.995">' +
				'<g data-name="Component 2 – 32" transform="translate(0 0.354)">' +
				'<g data-name="Component 1 – 13" transform="translate(11.141 11.287) rotate(-90)">' +
				'<path data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/>' +
				'</g>' +
				'<path data-name="Path 34" d="M0,0V16.459" transform="translate(0 5.622) rotate(-90)" fill="none" stroke="#24272a" stroke-width="1"/>' +
				'</g>' +
				'</svg>' +
				'</button>' +
				'</li>' +
				'<li>' +
				'<button type="button" class="button button--next" aria-label="Next Slide Button">' +
				'<svg width="17.492" height="11.995" viewBox="0 0 17.492 11.995">' +
				'<g data-name="Component 2 – 32" transform="translate(0 0.354)">' +
				'<g data-name="Component 1 – 13" transform="translate(11.141 11.287) rotate(-90)">' +
				'<path  data-name="Path 27" d="M31.712,31.425l-5.644,5.644-5.644-5.644" transform="translate(-20.425 -31.425)" fill="none" stroke="#24272a" stroke-miterlimit="10" stroke-width="1"/>' +
				'</g>' +
				'<path data-name="Path 34" d="M0,0V16.459" transform="translate(0 5.622) rotate(-90)" fill="none" stroke="#24272a" stroke-width="1"/>' +
				'</g>' +
				'</svg>' +
				'</button>' +
				'</li>';

			// slides.forEach((slide) => {
			// 	const title = slide.querySelector('.slide__title');
			// 	wrapFirstWord(title);
			// });

			ctrls.querySelector('.button--prev').addEventListener('click', function () {
				prevSlide(true);
			});
			ctrls.querySelector('.button--next').addEventListener('click', function () {
				nextSlide(true);
			});

			carousel.appendChild(ctrls);

			// carousel.getElementsByClassName('slide__container').appendChild(ctrls);
			// carousel.querySelector('.slide__content').appendChild(ctrls);
			// carousel.forEachElement('.slide__content').appendChild(ctrls);

			// If the carousel is animated or a slide navigation is requested in the settings, anoter unordered list that contains those elements is added. (Note that you cannot supress the navigation when it is animated.)
			if (settings.slidenav || settings.animate) {
				slidenav = document.createElement('ul');

				slidenav.className = 'slidenav';

				if (settings.animate) {
					const li = document.createElement('li');

					if (settings.startAnimated) {
						li.innerHTML =
							'<button data-action="stop"><span class="visuallyhidden">Stop Animation </span>￭</button>';
					} else {
						li.innerHTML =
							'<button data-action="start"><span class="visuallyhidden">Start Animation </span>▶</button>';
					}

					slidenav.appendChild(li);
				}

				if (settings.slidenav) {
					forEachElement(slides, function (el, i) {
						const li = document.createElement('li');
						const klass = i === 0 ? 'class="current" ' : '';
						const kurrent =
							i === 0 ? ' <span class="visuallyhidden">(Current Item)</span>' : '';

						li.innerHTML = `<button ${klass}data-slide="${i}"><span class="visuallyhidden">News</span> ${
							i + 1
						}${kurrent}</button>`;
						slidenav.appendChild(li);
					});
				}

				slidenav.addEventListener(
					'click',
					function (event) {
						const button = event.target;
						if (button.localName === 'button') {
							if (button.getAttribute('data-slide')) {
								stopAnimation();
								setSlides(button.getAttribute('data-slide'), true);
							} else if (button.getAttribute('data-action') === 'stop') {
								stopAnimation();
							} else if (button.getAttribute('data-action') === 'start') {
								startAnimation();
							}
						}
					},
					true,
				);

				carousel.className = `${settings?.additionalClasses}section section--carousel active carousel with-slidenav`;
				carousel.appendChild(slidenav);
			}

			// Add a live region to announce the slide number when using the previous/next buttons
			const liveregion = document.createElement('div');
			liveregion.setAttribute('aria-live', 'polite');
			liveregion.setAttribute('aria-atomic', 'true');
			liveregion.setAttribute('class', 'liveregion visuallyhidden');
			carousel.appendChild(liveregion);

			// After the slide transitioned, remove the in-transition class, if focus should be set, set the tabindex attribute to -1 and focus the slide.
			slides[0].parentNode.addEventListener('transitionend', function (event) {
				const slide = event.target;
				removeClass(slide, 'in-transition');
				if (hasClass(slide, 'current')) {
					if (setFocus) {
						slide.setAttribute('tabindex', '-1');
						slide.focus();
						setFocus = false;
					}
				}
			});

			// When the mouse enters the carousel, suspend the animation.
			carousel.addEventListener('mouseenter', suspendAnimation);

			// When the mouse leaves the carousel, and the animation is suspended, start the animation.
			carousel.addEventListener('mouseleave', function () {
				if (animationSuspended) {
					startAnimation();
				}
			});

			// When the focus enters the carousel, suspend the animation
			carousel.addEventListener('focusin', function (event) {
				if (!hasClass(event.target, 'slide')) {
					suspendAnimation();
				}
			});

			// When the focus leaves the carousel, and the animation is suspended, start the animation
			carousel.addEventListener('focusout', function (event) {
				if (!hasClass(event.target, 'slide') && animationSuspended) {
					startAnimation();
				}
			});

			// Set the index (=current slide) to 0 – the first slide
			index = 0;
			setSlides(index);

			// If the carousel is animated, advance to the
			// next slide after 5s
			if (settings.startAnimated) {
				timer = setTimeout(nextSlide, 50000000);
			}
		}
	}

	// Making some functions public
	return {
		init,
		next: this.nextSlide,
		prev: this.prevSlide,
		goto: this.setSlides,
		stop: this.stopAnimation,
		start: this.startAnimation,
	};
};

export default CTCarousel;

// Below works but doesn't loop around:

const scrollMenu = document.querySelector('.scroll-menu');
const leftArrow = document.querySelector('.left-arrow');
const rightArrow = document.querySelector('.right-arrow');
let scrollPosition = 0;
const itemWidth = 220; // Adjust this value based on your item width
function scrollToLeft() {
	scrollPosition = Math.max(0, scrollPosition - itemWidth);
	scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
}
function scrollToRight() {
	const containerWidth = document.querySelector('.scroll-container').offsetWidth;
	const menuWidth = scrollMenu.scrollWidth;
	if (scrollPosition < menuWidth - containerWidth) {
		scrollPosition = Math.min(menuWidth - containerWidth, scrollPosition + itemWidth);
		scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
	}
}

if (leftArrow) {
	leftArrow.addEventListener('click', scrollToLeft);
}
if (rightArrow) {
	rightArrow.addEventListener('click', scrollToRight);
}

// leftArrow.addEventListener('click', scrollToLeft);
// rightArrow.addEventListener('click', scrollToRight);

// Below loops around but there is a gap when you try to go left from first item:

// const scrollMenu = document.querySelector(".scroll-menu");
// const leftArrow = document.querySelector(".left-arrow");
// const rightArrow = document.querySelector(".right-arrow");
// let scrollPosition = 0;
// const itemWidth = 220; // Adjust this value based on your item width
// const itemsCount = document.querySelectorAll(".item").length;
// const menuWidth = itemWidth * itemsCount;
// scrollMenu.style.width = `${menuWidth}px`;
// function scrollToLeft() {
//   scrollPosition = (scrollPosition - itemWidth + menuWidth) % menuWidth;
//   scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }
// function scrollToRight() {
//   scrollPosition = (scrollPosition + itemWidth) % menuWidth;
//   scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }
// leftArrow.addEventListener("click", scrollToLeft);
// rightArrow.addEventListener("click", scrollToRight);

// Below works but lands on halfway of some labels:

// const scrollMenu = document.querySelector(".scroll-menu");
// const leftArrow = document.querySelector(".left-arrow");
// const rightArrow = document.querySelector(".right-arrow");
// let scrollPosition = 0;
// const itemWidth = 220; // Adjust this value based on your item width
// const itemsCount = document.querySelectorAll(".item").length;
// const menuWidth = itemWidth * itemsCount;
// scrollMenu.style.width = `${menuWidth * 3}px`; // Triple the menu width
// // Clone the menu items and append them to the end of the scroll menu
// const clonedItems = scrollMenu.innerHTML;
// scrollMenu.innerHTML += clonedItems;
// scrollMenu.innerHTML += clonedItems;
// function scrollToLeft() {
//   scrollPosition = (scrollPosition - itemWidth + menuWidth) % menuWidth;
//   scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }
// function scrollToRight() {
//   scrollPosition = (scrollPosition + itemWidth) % menuWidth;
//   scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }
// leftArrow.addEventListener("click", scrollToLeft);
// rightArrow.addEventListener("click", scrollToRight);

// Below works but lands on halfway of some labels:

// const scrollMenu = document.querySelector('.scroll-menu');
// const leftArrow = document.querySelector('.left-arrow');
// const rightArrow = document.querySelector('.right-arrow');

// let scrollPosition = 0;
// const itemWidth = 220; // Adjust this value based on your item width
// const itemsCount = document.querySelectorAll('.item').length;
// const menuWidth = itemWidth * itemsCount;
// if (scrollMenu) {
// 	scrollMenu.style.width = `${menuWidth * 3}px`; // Triple the menu width

// 	// Clone the menu items and append them to the end of the scroll menu
// 	const clonedItems = scrollMenu.innerHTML;
// 	scrollMenu.innerHTML += clonedItems;
// 	scrollMenu.innerHTML += clonedItems;
// }

// function scrollToLeft() {
// 	scrollPosition = (scrollPosition - itemWidth + menuWidth) % menuWidth;
// 	scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }

// function scrollToRight() {
// 	scrollPosition = (scrollPosition + itemWidth) % menuWidth;
// 	scrollMenu.style.transform = `translateX(-${scrollPosition}px)`;
// }

// if (leftArrow) {
// 	leftArrow.addEventListener('click', scrollToLeft);
// }
// if (rightArrow) {
// 	rightArrow.addEventListener('click', scrollToRight);
// }
